<template>
  <AdminTemplate>
    <div class="is-flex ai-center js-center min-height-screen-120">
      <el-row class="w-100">
        <el-col :span="10" :xs="{ span: 20, offset: 2 }" :offset="7">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-position="top"
            @submit.prevent.native="create()"
          >
            <el-form-item label="ชื่อห้อง" prop="roomName">
              <el-input v-model="form.roomName"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                class="w-100 mg-t-3"
                :loading="loading"
                @click="create()"
                >สร้างห้อง</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </AdminTemplate>
</template>
<script>
import { HTTP } from "@/service/axios";
import AdminTemplate from "@/template/VoteAdminTemplate";
export default {
  components: {
    AdminTemplate
  },
  computed: {
    token() {
      return this.$store.state.token;
    }
  },
  data() {
    return {
      form: {
        roomName: ""
      },
      loading: false,
      rules: {
        roomName: [
          { required: true, message: "กรุณากรอกชื่อห้อง", trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    this.$store.commit("SET_ROOM", null);
    this.$store.commit("SET_STEP", null);
  },
  methods: {
    create() {
      this.loading = true;
      this.$refs.form.validate(valid => {
        if (valid) {
          HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
          HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
          HTTP.post("room", { name: this.form.roomName })
            .then(async res => {
              //console.log('room',res)
              if (res.data.success) {
                await this.$store.commit("SET_ROOM", res.data.obj);
                this.$router.push("/lobby");
              }
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>